@use '~/src/breakpoints.scss' as *;

@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

/* === Footer === */
.footer-container {
    padding:0;
    background-color:#171717;
    width: 100%;

    overflow-x: hidden;
}

.footer-top {
    padding: 60px 0px 60px 0px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include lg {flex-direction: row;}
    @include md {flex-direction: row;}
    @include sm {flex-direction: column;}
    @include xs {flex-direction: column;}

    color: white;
    padding-bottom: 80px;
}

.footer-header {
    padding-bottom: 8px;
    font-weight: 400;
    color: white;

    font-size: 16px;
    @include lg {font-size: 14px;}
    @include md {font-size: 14px;}
    @include sm {font-size: 14px;}
    @include xs {font-size: 14px;}
}

.footer-paragraph {
    font-size: 16px;
    line-height: 2rem;
    color: #cbcbcb;

    @include lg {font-size: 14px; line-height: 1.5rem;}
    @include md {font-size: 14px; line-height: 1.5rem;}
    @include sm {font-size: 14px; line-height: 1.5rem;}
    @include xs {font-size: 14px; line-height: 1.5rem;}
}

.footer-left {
    width: 25%;
    @include lg {width: 25%;}
    @include md {width: 20%;}
    @include sm {width: 70%; margin-bottom: 2rem;}
    @include xs {width: 100%; margin-bottom: 2rem;}
}

.footer-left > p {
    margin: 0;
}

.footer-right {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include lg {flex-direction: row;}
    @include md {display: grid; grid-template-columns: 1fr 1fr 1fr; row-gap: 2rem;}
    @include sm {display: grid; grid-template-columns: 1fr 1fr 1fr; row-gap: 2rem; width: 100%;}
    @include xs {display: grid; grid-template-columns: 1fr 1fr 1fr; row-gap: 2rem; width: 100%;}
}

.footer-right-col-header {
    font-size: 16px;
    padding-bottom: 8;
    @include lg {font-size: 14px;}
    @include md {font-size: 14px;}
    @include sm {font-size: 14px;}
    @include xs {font-size: 14px;}
}

.footer-right-col > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-right-col > ul > li {
    text-indent: 0;
    color: #cbcbcb;
    font-size: 16px;
    line-height: 2rem;

    @include lg {font-size: 14px; }
    @include md {font-size: 14px; }
    @include sm {font-size: 14px; }
    @include xs {font-size: 14px; }
}

.footer-mid {
    background-color: #616161;
    height: 0.1px;
    margin: 0;
}

.footer-bottom {
    font-size: 14px;
    color: #cbcbcb;
    padding: 25px 0px 30px 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include xs {display: grid; grid-template-columns: 1fr 1fr; row-gap: 1.5rem; width: 100%;}
}

.copyright {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
}

.footer-right-col > ul > li > a {
    text-indent: 0;
    color: #cbcbcb;
    line-height: 2rem;
}