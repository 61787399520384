@use '~/src/breakpoints.scss' as *;

@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

/* === Hero === */
.hero-wide-home-bg {
    min-height: 600px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.35) 41%, rgba(0, 0, 0, 0.2)), url("https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60addfe474d3e3a5fa3ef5df_Life%20at%20Project%20Hero%20Cover.png");

    background-size: cover;
    background-position: bottom;
    width: 100%;
    @include lg {min-height: 600px; height: 600px; flex-direction: row;}
    @include md {min-height: 600px; height: 600px; flex-direction: row;}
    @include sm {min-height: 500px; height: 500px; flex-direction: column;}
    @include xs {min-height: 500px; height: 500px; background-size: cover;}
}

.hero-wide-ipad-bg {
    min-height: 550px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.35) 41%, rgba(0, 0, 0, 0.2)), url("https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60eff7967c05c114fc2fb3a2_Screen%20Shot%202021-07-15%20at%206.51%201.png")
}

.hero-wide {
    padding-top: 70px;
    width: 40%;
    color: white;
    @include lg {width: 50%;}
    @include md {width: 50%;}
    @include sm {width: 100%;}
    @include xs {padding-top: 40px; width: 100%;}
}

.hero-wide-text-group {
    line-height: 110%;
    margin: 0 0 2rem 0;
}

.hero-wide-text {
    margin: 0 0 2rem 0;
}

.hero-wide-point {
    line-height: 200%;
    margin: 0px;

    color: white;
    font-size: 18px;
}

.hero-wide-under-text {
    line-height: 130%;
    font-weight: 100;
    font-size: 24px;
    margin-top: 24px;
    width: 80%;
    color: white;
}

.hero-wide-button {
    width: 70%;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 8px;
    display: flex;
    font-size: 18px;
    justify-content: center;
    background-color: var(--red);
}
