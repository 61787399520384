@use '~/src/breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.blog-article-small-group {
    display: flex;
    flex-direction: row;
    max-width: 285px;
    min-width: 200px;
    overflow: hidden;

    @include lg {width: 100%; }
    @include md {width: 100%; }
    @include sm {width: 100%; }
    @include xs {width: 100%;}
}

.blog-article-small-left {
    min-width: 75px;
    height: 75px;
    background-color: grey;

    border-radius: 8px;

    overflow: hidden;
}

.blog-article-small-left > img {
    width: 100%;
    height: 100%;
}

.blog-article-small-right {
    padding: 0rem 0rem 0rem 1rem;
}

.blog-article-small-subject {
    margin: 0.25rem 0 0.25rem 0;
    line-height: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #005CE7;

    @include lg {font-size: 12px; }
    @include md {font-size: 10px; }
    @include sm {font-size: 10px; }
    @include xs {font-size: 10px;}
}

.blog-article-small-topic {
    margin: 0 !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;

    @include lg {font-size: 16px; }
    @include md {font-size: 16px; }
    @include sm {font-size: 16px; }
    @include xs {font-size: 16px;}
}

.blog-article-small-paragraph {
    margin: 0.5rem 0 0 0 !important;
    font-size: 16px;
    line-height: 130%;
}