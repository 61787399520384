@use '~/src/breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.sticky {
    position: fixed;
    margin: auto 100px 0 auto;
    padding: 0 1rem;
    bottom: 2rem;
    z-index: 99;
    right: 10px;
    width: 300px;

    background-color: white;
    outline: solid 1px grey;
    border-radius: 4px;
    cursor: pointer;

    @include lg {}
    @include md {right: 10px; margin-right: 50px;}
    @include sm {right: 10px; margin-right: 20px;}
    @include xs {
        border-radius: 0;
        padding: 0.5rem 20px;
        bottom: 0;
        margin: 0;
        right: 0;
        align-items: center;
        width: 100vw;
    }
}

.sticky > p {
    margin: 0;
}

.sticky-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;}

.sticky-bar > p {
    font-weight: 400;
    font-size: 18px;
}

.sticky-bar > img {
    width: 20px;
    height: 20px;
}

.sticky-contents {
    display: none;
    @include lg {}
    @include md {}
    @include sm {}
    @include xs {}
}

.sticky-contents > p {
    border-top: 1px solid rgb(178, 178, 178);
    margin: 0;
    padding: 0.5rem 0;
}

.sticky-contents > p >a {
    color: #333;
    font-size: 16px;
}

.trialsHSC-grid-gradient {
    display: none;

    @include lg {}
    @include md {}
    @include sm {}
    @include xs {
        display: block;
        height: 150px;
        margin-top: -165px;
        background-image: linear-gradient(rgba(255,0,0,0), rgb(213, 213, 213));
    }
}

.gridEnglish, .gridEconomics, .gridChem, .gridPhysics, .gridM4, .gridM3, .gridM2 {
    @include lg {}
    @include md {}
    @include sm {}
    @include xs {display: none;}
}

.trialsHSC-subject-heading {
    display: flex;
    flex-direction: column;

    padding: 0 80px;
    @include lg {padding: 0 20px;}
    @include md {padding: 0 20px;}
    @include sm {padding: 0 20px;}
    @include xs {padding: 0 20px;}
}

.trialsHSC-subject-heading > h2 {
    margin-bottom: 0rem;
}

.trialsHSC-subject-heading > p {
    font-size: 18px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.trialsHSC-subject-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    padding: 0 80px;

    align-items: start;

    @include lg {padding: 0 20px;}
    @include md {padding: 0 20px; overflow: scroll;}
    @include sm {padding: 0 20px; overflow: scroll;}
    @include xs {padding: 0 20px; grid-template-columns: 1fr;}
}

.trialsHSC-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-content: start;
    padding: 0 80px;

    @include lg {padding: 0 20px;}
    @include md {padding: 0 20px;overflow: scroll;}
    @include sm {padding: 0 20px; overflow: scroll;}
    @include xs {grid-template-columns: 1fr; padding: 0 20px;}
}

.trialsHSC-right-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-content: start;
    padding: 0 80px;

    width: 100%;
    margin-top: 2rem;

    @include lg {padding: 0 20px;}
    @include md {padding: 0 20px; overflow: scroll;}
    @include sm {padding: 0 20px; overflow: scroll;}
    @include xs {grid-template-columns: 1fr; padding: 0 20px;}
}

.trialsHSC-block {
    margin-bottom: 3rem;
}