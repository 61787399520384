@use '~/src/breakpoints.scss' as *;

.pricing-container {
    width: 90%;
    margin: 3rem auto 5rem auto;
    @include lg {margin: 3rem auto 5rem auto;}
    @include md {margin: 3rem auto 5rem auto;}
    @include sm {margin: 3rem auto 5rem auto;}
    @include xs {margin: 3rem 0 5rem 0; width: 100%;}
}

.pricing-top {
    display: flex;
    flex-direction: row;
    @include lg {flex-direction: row;}
    @include md {flex-direction: row;}
    @include sm {flex-direction: row;}
    @include xs {flex-direction: column;}
}

.pricing-right-one > p {
    display: inline;
}

.pricing-red {
    font-weight: 600;
    color: var(--red)
}

.pricing-top-left {
    width: 50%;
    @include lg {flex-direction: row;}
    @include md {display: none;}
    @include sm {display: none;}
    @include xs {display: none;}
}

.pricing-top-left > img { 
    @include md {width: 80%}
}

.pricing-top-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 50%;
    @include lg {}
    @include md {width: 100%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}
}

.pricing-right-one {
    margin-bottom: 1rem;
}

.pricing-right-one > h2 {
    margin-bottom: 1rem;
    margin-top: 0;
}

.pricing-right-one > p {
    margin-bottom: 0rem;
    font-size: 16px;
    font-weight: 400;
}

.pricing-right-three {
    margin-bottom: 2rem;
}

.pricing-right-two {
    border-top: solid lightgrey 1px;
    border-bottom: solid lightgrey 1px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    min-width: 100%;

    margin-bottom: 1rem;
}

.pricing-right-two > p {
    font-size: 16px;
    max-width: 25%;
    font-weight: 400;

    margin: 0.5rem 0 0.5rem 0;
}

.pricing-point-group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
}

.pricing-point-group > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pricing-point-group > div > img {
    width: 20px;
    height: 20px;
}

.pricing-point-group > div > p {
    margin:  0 0 0 0.5rem;
    padding: 0;
    font-size: 16px;
}

.pricing-one {
    grid-column: 1;
    grid-row: 1;
}

.pricing-two {
    grid-column: 1;
    grid-row: 2;
}

.pricing-three {
    grid-column: 1;
    grid-row: 3;
}

.pricing-four {
    grid-column: 1;
    grid-row: 4;
}

.pricing-five {
    grid-column: 1;
    grid-row: 5;
}

.pricing-six {
    grid-column: 1;
    grid-row: 6;
}

.pricing-right-four {
    width: 100%;
}

.pricing-cta {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--red);
    border-radius: 8px;
}

.pricing-cta > p {
    color: white;
    font-weight: 600;
}
