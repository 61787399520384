@use '~/src/breakpoints.scss' as *;

.cta-section {
    background-color: lightpink;
    padding: 40px 0;
}

.cta-left {
    width: 50%;
    @include lg {width: 60%;}
    @include md {width: 100%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}
}

.cta-left > h2 {
    margin-bottom: 0;
    @include lg {width: 100%;}
    @include md {width: 50%;}
    @include sm {width: 80%;}
    @include xs {width: 100%;}
}

.cta-point-group {
    display: grid;
    grid-template-columns: 1fr, 1fr;
    gap: 20px;
}

.cta-one {
    grid-column: 1 / 2;
    grid-row: 1;
}

.cta-two {
    grid-column: 2 / 2;
    grid-row: 1;
}

.cta-three {
    grid-column: 1 / 2;
    grid-row: 2;
}

.cta-four {
    grid-column: 2 / 2;
    grid-row: 2;
}

.cta-five {
    grid-column: 1 / 2;
    grid-row: 3;
}

.cta-six {
    grid-column: 2 / 2;
    grid-row: 3;
}

.cta-point-group > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include lg {align-items: center;}
    @include md {align-items:flex-start;}
    @include sm {align-items:flex-start;}
    @include xs {align-items:flex-start;}
}

.cta-point-group > div > img {
    width: 20px;
    height: 20px;
}

.cta-point-group > div > p {
    margin:  0 0 0 0.5rem;
    padding: 0;

    
    @include xs {padding: 0;}
}

.cta-container > a {
    display: flex;
    justify-content: center;
    background-color: var(--red);
    border-radius: 8px;

    width: 30%;
    margin-top: 2rem;
    padding: 1rem;

    color: white;
    font-weight: 600;

    @include lg {width: 30%;}
    @include md {width: 40%;}
    @include sm {width: 40%;}
    @include xs {width: 100%;}
}