@use '~/src/breakpoints.scss' as *;

.timetable-override {
    background-color: #FFFEFA !important;
}

.timetable-container {
    display: flex;
    flex-direction: row;
    padding: 2rem 0 3rem 0;
}

#timetable-time-desktop {
    display: flex;
}

#timetable-time-mobile {
    display: none;
}

.timetable-left {
    position: sticky;
    top: 12px;

    width: 30%;
    align-self: flex-start;
    height: auto;
}

.timetable-left > h2 {
    margin-bottom: 1rem;
}

.timetableSection-container {
    overflow-x: hidden;
    @include md {flex-direction: column !important;}
}

.timetableSection-info-left {
    @include md {width: 100%;}
    @include xs {margin-top: 1rem;}
}

.timetableSection-info-right {
    @include md {width: 100%;}
}

.timetable-filters {
    font-size: 14px;
    font-weight: 600;
}

.timetable-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.timetable-label > input {
    margin: 0;
}

.timetable-label > p {
    margin: 0 0 0 0.3rem;
    color: gray;
    font-weight: 300;
    cursor: pointer;
    font-size: 16px;
}

.campus-filters {
    margin-bottom: 1.5rem;
}

.year12Timetable {
    margin-bottom: 1.5rem;
}

.year11Timetable {
    margin-bottom: 1rem;
}

.juniorTimetable {
    margin-bottom: 1rem;
}

.timetable-right {
    width: 70%;
    margin-top: 1.7rem;
}

.timetable-subject-heading {
    font-weight: 600;
    margin-left: 1rem;
    font-size: 24px;
}

.timetable-group {
    display: grid;
    
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 5rem;

    grid-template-columns: 1fr 1fr 1fr;
    @include lg {grid-template-columns: 1fr 1fr 1fr;}
    @include md {grid-template-columns: 1fr 1fr;}
}

.timetableSection-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include lg {grid-template-columns: 1fr 1fr 1fr;}
    @include md {grid-template-columns: 1fr 1fr 1fr;}

    gap: 1rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.timetableSection-info-header {
    @include md {
        display: flex; 
        flex-direction: row !important; 
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    @include sm {
        display: flex; 
        flex-direction: row !important; 
        justify-content: space-between;
        margin-bottom: 1rem;
    }
}

.timetableSection-info-header > h2 {
    @include md {width: 50%;}
    @include sm {width: 50%;}
}

.timetableSection-hero-button {
    width: 90%;
    @include lg {width: 100%;}
    @include md {width: 100%;}
    @include sm {width: 100%;}

}

.timetable-slot {
    display: flex;
    flex-direction: column;
    align-items:flex-start;

    min-width: 220px;
    background-color: white;
    box-shadow: 0px 10px 20px rgb(0, 0, 0, 5%);
    border-radius: 10px;
    padding: 1rem 1rem;

    border: 1px solid rgb(200, 200, 200, 20%);
}

.timetable-slot > p {
    margin: 0;  
    align-items: left;
}

.timetable-status {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.timetable-status-capacity {
    margin: 0 0 0 0.25rem;
    color: rgb(252, 0, 0, 90%);
    font-size: 12px;
    font-weight: 400;
}

.timetable-status-available {
    margin: 0 0 0 0.25rem;
    color: rgb(0, 71, 252, 56%);
    font-size: 12px;
}

.timetable-time {
    font-size: 24px;
    font-weight: 600;
    @include lg {font-size: 20px;}
    @include md {font-size: 20px;}
}

.timetable-stream {
    font-size: 14px;
    font-weight: 300;
}

.timetable-campus-online {
    background-color: rgb(142, 242, 125, 35%);
    font-size: 12px;
    line-height: 1rem;

    padding: 0.25rem 0.5rem;
    border-radius: 8px;
}

.timetable-campus-chatswood {
    background-color: rgb(78, 149, 255, 35%);
    font-size: 12px;
    line-height: 1rem;

    padding: 0.25rem 0.5rem;
    border-radius: 8px;
}

.timetable-filter {
    display: none;
}

.timetable-description {
    display: none;
}




/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
@media only screen and (max-width: 767px) {
    .timetable-container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .timetable-left {
        position: static;
        top: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }

    .timetable-left-top {
        margin: 0 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .timetable-left-top-group {
        margin-right: 1rem;
    }

    .timetable-left-top-group > h2 {
        margin-bottom: 0.5rem;
    }

    .timetable-filter {
        display: flex;
    }
    
    .timetable-description {
        display: flex;
    }
    

    .timetable-drawer {
        background-color: white;
        position: fixed;
        z-index: 10;
        top: 0;
        height: 100vh;
        padding: 1rem 4rem 10rem 2rem;
        left: 0;
    }

    .timetable-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 0.25rem;
    }
    
    .timetable-label > input {
        margin: 0;
    }
    
    .timetable-label > p {
        margin: 0 0 0 0.3rem;
        color: #333;
        font-weight: 300;
        cursor: pointer;
        font-size: 18px;
    }
    

    .timetable-drawer-cover {
        background-color: rgb(0, 0, 0, 20%);
        position: fixed;
        z-index: 1;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .timetable-right {
        width: 100%;
        margin-top: 1.7rem;
    }

    .timetable-subject-heading {
        font-size: 24px;
        font-weight: 600;
        margin-left: 1rem;
    }
    
    .timetable-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        margin-top: 0rem;
        margin-bottom: 3rem;
    }
    
    .timetableSection-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        padding-bottom: 0rem;
        margin-bottom: 0rem;
    }
    
    .timetable-slot {
        display: flex;
        flex-direction: column;
        align-items:flex-start;
    
        min-width: 100px;
        width: 100%;
        background-color: white;
        
        border-radius: 10px;
        padding: 0.75rem 1rem;
    
        box-shadow: 0px 10px 20px rgb(0, 0, 0, 5%);
        border: 1px solid rgb(200, 200, 200, 20%);
    }


.timetable-slot > p {
    margin: 0;  
    align-items: left;
}

#timetable-time-desktop {
    display: none;
}

#timetable-time-mobile {
    display: flex;
}

.timetable-status {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}

.timetable-status-capacity {
    margin: 0 0 0 0.25rem;
    color: rgb(252, 0, 0, 90%);
    font-size: 12px;
    font-weight: 400;
}

.timetable-status-available {
    margin: 0 0 0 0.25rem;
    color: rgb(0, 71, 252, 56%);
    font-size: 12px;
}

.timetable-time {
    font-size: 20px;
    font-weight: 600;
}

.timetable-stream {
    font-size: 14px;
    font-weight: 300;
}

.timetable-time-pm {
    text-transform: lowercase;
}

.timetable-campus-online {
    background-color: rgb(142, 242, 125, 35%);
    font-size: 12px;
    line-height: 1rem;

    padding: 0.25rem 0.5rem;
    border-radius: 8px;
}

.timetable-campus-chatswood {
    background-color: rgb(78, 149, 255, 35%);
    font-size: 12px;
    line-height: 1rem;

    padding: 0.25rem 0.5rem;
    border-radius: 8px;
}


}