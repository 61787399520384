@use '~/src/breakpoints.scss' as *;

@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.ipad-tablist-selected {
    background-color: #E7EDE6;
    font-weight: 600;
    border-radius: 16px;
}

/* === Info Section === */
.ipad-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    max-width: 1400px;
    margin: 0 auto 0 auto;

    padding: 6rem 0 4rem 80px;
    @include lg {padding: 6rem 40px 4rem 40px;}
    @include md {padding: 6rem 40px 4rem 40px;}
    @include sm {padding: 4rem 20px 4rem 20px;}
    @include xs {padding: 3rem 20px 4rem 20px;}

    @include lg {flex-direction: row;}
    @include md {flex-direction: column;}
    @include sm {flex-direction: column;}
    @include xs {flex-direction: column;}
}

/* === Info Section - Left === */
.ipad-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 25%;
    min-width: 400px;
    @include lg {min-width: 400px; width: 80%; margin-right: 0.5rem;}
    @include md {min-width: 400px; width: 100%; margin-right: 0.5rem;}
    @include sm {min-width: 400px; width: 100%; margin-right: 0.5rem;}
    @include xs {min-width: 200px; width: 100%; margin-right: 0.5rem;}
}

.ipad-section-one {
    margin-top: 4rem;
    background-color: #4A816B;
    color: white;
}

.ipad-right {
    width: 75%;
    @include lg {width: 100%;}
    @include md {width: 100%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}
}

.ipad-img {
    width: 110%;
    @include lg {width: 100%;}
    @include md {width: 100%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}

}

.collage > img {
    margin: 2rem 0 3rem 0;
    width: 100%;
}

.ipad-header > h2 {
    margin-bottom: 1rem;
    margin-top: 0;

    font-weight: 400;
}

.ipad-tablist {
    width: 90%;
    list-style: none;
    line-height: 2.25rem;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;

    padding-left: 0px;

    display: flex;
    
    flex-direction: column;
    @include lg {flex-direction: column;}
    @include md {flex-direction: row; justify-content: space-between; width: 100%;}
    @include sm {flex-direction: column;}
    @include xs {flex-direction: column; width: 100%;}
}

.ipad-tablist-item {
    padding: 1rem 1.5rem 0.5rem 1.5rem;

    @include lg {padding: 1rem;}
    @include md {padding: 1rem; margin: 0;}
    @include sm {padding: 1rem;}
}

.ipad-tablist-item-heading {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 24px;
    color: black;
}

.ipad-tablist-item > p {
    font-size: 16px;
    margin-top: 8px;
    @include lg {margin: 0;}
    @include md {margin: 0; font-size: 14px;}
    @include sm {margin: 0; font-size: 14px;}
}