@use '~/src/breakpoints.scss' as *;

@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

#blog-section {
    @include lg {display: none;}
    @include md {display: none;}
    @include sm {display: none;}
    @include xs {display: none;}
}

.blog-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 2rem;
}

.blog-top-right {
    width: 70%;
}

.blog-large {
    display: flex;
    flex-direction: row;
}

.blog-large-text > h3 {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    color: black;
}

.blog-large-img {
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
}

.blog-large-img > img {
    width: 100%;
}

.blog-top-left {
    width: 20%;
}

.blog-top-left > h2 {
    margin-top: 0;
}

.blog-top-left > a {
    color: #0294FF;
}

.blog-top > h2 {
    width: 30%;
}

.blog-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.blog-div {
    max-width: 24%;
}

.blog-image {
    height: 200px;
    overflow: hidden;;
}

.blog-image > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}

.blog-large-text-group {
    margin-left: 2rem;
    width: 40%
}

.blog-text {
    min-height: 200px;

    margin-bottom: 2rem;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.blog-title > p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    padding: 0;
}

.blog-desc > p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */

@media only screen and (max-width: 478px) {
    .blog-top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        margin-bottom: 2rem;
    }

    .blog-top-left {
        width: 100%;
        margin-bottom: 1rem;
    }
    
    .blog-top-left > h2 {
        margin-top: 0;
    }
    
    .blog-top-left > a {
        color: #0294FF;
    }
    
    .blog-top-right {
        width: 100%;
    }
    
    .blog-large {
        display: flex;
        flex-direction: column;
    }
    
    .blog-large-text > h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .blog-large-img {
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
    
    .blog-large-img > img {
        width: 100%;
    }

    .blog-bottom {
        display: none;
    }


    .blog-div {
        max-width: 24%;
    }

    .blog-image > img {
        width: 100%;
    }

    .blog-large-text-group {
        margin-left: 0rem;
        width: 100%
    }

    .blog-text {
        min-height: 200px;

        margin-bottom: 2rem;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .blog-title > p {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 0.5rem;
        padding: 0;
    }

    .blog-desc > p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        padding: 0;
    }

    .blog-author-image {
        width: 30px;
    }
}