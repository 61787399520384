@use '~/src/breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

/* === Hero === */
.hero-home-bg {
    position: relative;
    height: 488px;
    //background-image: var(--hero-home-img);
    background-size: cover;
    width: 100%;
    
    background-position: 0;
    @include lg {background-position: -60px;}
    @include md {background-position: -200px;}
    @include sm {background-position: -400px;}
    @include xs {background-position: 860px;}
}

.blog-hero {
    color: white;
    position: relative;
    background-color: green;

    padding: 70px 80px;
    @include lg {padding: 70px 20px;}
    @include md {padding: 70px 20px;}
    @include sm {padding: 70px 20px;}
    @include xs {padding: 60px 20px 40px;}

    width: 100%;
    
}

.blog-hero-text-group {
    width: 50%;
    @include lg {width: 60%; max-width: 600px; min-width: 600px;}
    @include md {width: 60%; max-width: 500px; min-width: 500px;}
    @include sm {width: 90%; max-width: 350px; min-width: 350px;}
    @include xs {width: 100%;}
}

.hero-overlay {
    @include sm {background-color: rgb(0,0,0,50%);}
    @include xs {background-color: rgb(0,0,0,50%);}
    position: absolute;
    width: 100%;
    height: 100%;
}

.hero-text {
    line-height: 110%;
    margin: 0px;
}

.blog-hero-under-text {
    line-height: 130%;
    font-weight: 100;
    margin-top: 24px;
    color: white;

    font-size: 24px;
    @include lg {font-size: 24px;}
    @include md {font-size: 24px;}
    @include sm {font-size: 20px;}
    @include xs {font-size: 18px;}

    width: 80%;
    @include lg {width: 80%;}
    @include md {width: 90%;}
    @include sm {width: 90%;}
    @include xs {width: 90%;}
}

.hero-button {
    width: 70%;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 8px;
    display: flex;
    font-size: 18px;
    justify-content: center;
    background-color: var(--red);
}

/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */

@media only screen and (max-width: 478px) {
    .hero-home-bg {
        position: relative;
        height: 480px;
        background-image: var(--hero-home-img);
        background-size: cover;
        background-position: 860px;
        width: 100%;

    }
    
    .hero {
        padding-top: 60px;
        width: 80%;
        color: white;
    
    }
    
    .hero-text {
        line-height: 110%;
        margin: 0px;
    }
    
    .hero-under-text {
        line-height: 130%;
        font-weight: 100;
        font-size: 18px;
        margin-top: 24px;
        width: 100%;
        color: white;
    }
    
    .hero-button {
        padding: 1rem 1rem 1rem 1rem;
        border-radius: 8px;
        display: flex;
        font-size: 18px;
        justify-content: center;
        background-color: var(--red);

        width: 100%;
    }
}