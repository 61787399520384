@use '~/src/breakpoints.scss' as *;

.history-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;

    @include lg {}
    @include md {flex-direction: column-reverse;}
    @include sm {flex-direction: column-reverse;;}
    @include xs {flex-direction: column-reverse;;}
}

.history-left {
    width: 65%;
    @include lg {}
    @include md {width: 100%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}
}

.history-left-top > h2 {
    margin-bottom: 0;
    width: 60%;
}

.history-left-bottom > h3 {
    margin-bottom: 0;
    width: 60%;
}

.history-right {
    @include lg {}
    @include md {display: flex; align-self: first baseline;}
    @include sm {display: flex; align-self: first baseline;}
    @include xs {display: flex; align-self: first baseline;}
}

.history-right > img {
    width: 300px;
    @include lg {width: 300px;}
    @include md {width: 100px;}
    @include sm {width: 100px;}
    @include xs {width: 100px;}
}

.tailored-support {
    width: 50%;
    margin: 4rem auto 4rem auto !important;

    @include lg {width: 50%;}
    @include md {width: 70%;}
    @include sm {width: 90%;}
    @include xs {width: 100%;}
}

.subjects-list {
    display: grid;
    gap: 1rem;
    margin-bottom: 4rem;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include lg {grid-template-columns: 1fr 1fr 1fr 1fr;}
    @include md {grid-template-columns: 1fr 1fr 1fr;}
    @include sm {grid-template-columns: 1fr 1fr;}
    @include xs {grid-template-columns: 1fr 1fr;}
}

.subject-item {
    max-width: 500px;
    border: #d8d8d8 1px solid;
    border-radius: 8px;
    overflow: hidden;
}

.subject-item > img {
    width: 100%;
    border-radius: 0;
}

.subject-item-textgroup {
    padding: 1rem;
}

.subject-item-subject {
    font-weight: 600;
    font-size: 21px;
    margin: 0;
}

.subject-item-paragraph {
    font-size: 16px;
    line-height: 130%;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

