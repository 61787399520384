@use 'breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

:root {
    --red: #EC4E4E;
    --ggs: 0.80;
    --hero-home-img: url("./assets/Hero.jpg");
    --hero-life-img: url("https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60addfe474d3e3a5fa3ef5df_Life%20at%20Project%20Hero%20Cover.png");
    --hero-english-img: url("https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6a742ae8076807fb1c4ec_English%20Cover.png");
}

* {
    box-sizing: border-box;
}

html {
    font-family: 'Archivo', sans-serif;
}

body {
    padding:0;
    margin:0;
    font-size: 18px;
    font-family: "Archivo";
    overflow-x: hidden;
}

img {
    border-radius: 8px;
}

a {
    text-decoration: none;
    color: white;
}

h1 {
    font-size: 52px;
    @include lg {font-size: 52px;}
    @include md {font-size: 42px;}
    @include sm {font-size: 36px;}
    @include xs {font-size: 36px;}
}

h2 {
    font-size: 42px;
    @include lg {font-size: 36px;}
    @include md {font-size: 32px;}
    @include sm {font-size: 32px;}
    @include xs {font-size: 32px;}
}

h3 {
    font-size: 36px;
    @include lg {font-size: 32px;}
    @include md {font-size: 24px;}
    @include sm {font-size: 24px;}
    @include xs {font-size: 24px;}
} 

p {
    display: flex;
    align-items: center;
    line-height: 1.75rem;
    font-weight: 400;
    color: #333;
}

.elementToBeTargeted {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container {
    padding: 0 80px 0 80px;
    @include lg {padding: 0 40px;}
    @include md {padding: 0 40px;}
    @include sm {padding: 0 20px;}
    @include xs {padding: 0 20px;}

    position: relative;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

/* === Banner === */
.banner {
    color: White;
    background-color: black;
    font-weight: 600;
    display: flex;
    justify-content: center;

}

.banner > p {
    font-size: 14px;
    font-weight: 600;
    margin: 8px;
    color: white;
}

.schools-list {
    margin-top: 4rem;
}

/* ====== SM SIZE ====== */
/* ====== SM SIZE ====== */
/* ====== SM SIZE ====== */
@media only screen and (max-width: 576px) {

    body {
        padding:0;
        margin:0;
        font-size: 16px;
        font-family: "Archivo";
        overflow-x: hidden;
    }
    
    img {
        border-radius: 8px;
    }
    
    a {
        text-decoration: none;
        color: white;
    }
    
    // h1 {
    //     font-size: 36px;
    // }
    
    // h2 {
    //     font-size: 32px
    // }
    
    // h3 {
    //     font-size: 24px;
    // } 
    
    p {
        display: flex;
        align-items: center;
        line-height: 1.75rem;
        font-weight: 300;

        color: #333;
    }

    // .container {
    //     padding: 0 20px 0 20px;
    //     position: relative;
    // }


}