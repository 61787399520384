@use '~/src/breakpoints.scss' as *;

.FAQ-container {
    margin-bottom: 5rem;
}

.FAQ-one {
    border-bottom: #e3e3e3 1px solid;
}

.FAQ-question > p {
    font-weight: 600;
    font-size: 24px;
}