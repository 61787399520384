@use '~/src/breakpoints.scss' as *;

@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.courseschedule {
    @include md {flex-direction: column !important;}
}

.courseschedules-info-right {
    @include md {width: 100% !important;}
}

.schedules-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    @include lg {flex-direction: row;}
    @include md {flex-direction: row;}
    @include sm {flex-direction: row;}
    @include xs {flex-direction: column; margin-bottom: 1rem;}

    gap: 1rem;
}

.schedules-container {
    display: flex;
    flex-direction: column;
    width: 48%;
    @include xs {width: 100%;}
}

.schedules-header {
    background-color: red;
    color: white;
    font-weight: 600;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem;
    
}

.schedules-one {
    background-color: #FF8F3F;
}

.schedules-two {
    background-color: #EC504D;
}

.schedules-three {
    background-color: #DA67E7;
}

.schedules-four {
    background-color: #6500AD;
}

.schedules-body {
    padding: 0rem 1rem 1rem 1rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: oldlace;
    height: 100%;
    font-size: 16px;
}

.schedules-body > ul {
    padding-left: 20;
}

.schedules-body > ul > li {
    line-height: 1.5rem;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 300;
}
