@use '~/src/breakpoints.scss' as *;

.tutors-featured {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; grid-template-rows: 1fr;
    margin-bottom: 2rem;
    margin-top: 2rem;

    
    @include lg {grid-template-columns: 1fr 1fr 1fr 1fr 1fr; grid-template-rows: 1fr;}
    @include md {grid-template-columns: 1fr 1fr 1fr 1fr 1fr; grid-template-rows: 1fr;}
    @include sm {grid-template-columns: 1fr 1fr; grid-template-rows: 1fr; margin-bottom: 1rem;}
    @include xs {grid-template-columns: 1fr; grid-template-rows: 1fr 1fr; margin-bottom: 0rem;}
}

.tutors-featured > img {
    grid-column-start: 1; grid-column-end: 3;
    
    width: 95%;
    @include lg {grid-column-start: 1; grid-column-end: 3;}
    @include md {grid-column-start: 1; grid-column-end: 4;}
    @include sm {grid-column-start: 1; grid-column-end: 1; width: 100%;}
    @include xs {grid-column-start: 1; grid-column-end: 1; width: 100%;}
}

.tutors-featured-right {
    grid-column-start: 3; grid-column-end: 6;
    @include lg {grid-column-start: 3; grid-column-end: 6;}
    @include md {grid-column-start: 4; grid-column-end: 6;}
    @include sm {grid-column-start: 2; grid-column-end: 2;}
    @include xs {grid-column: 1;}

    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-self: flex-end;
    width: 100%;
}

.tutors-featured-tag {
    font-size: 14px;
    margin: 0 0 8px 0;
    color: grey;
}

.tutors-featured-name {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0;
}

.tutors-featured-paragraph {
    font-size: 1rem;
    margin-bottom: 2rem;

    width: 65%;
    @include lg {width: 100%;}
    @include md {width: 100%;}
    @include sm {width: 80%;}
    @include xs {width: 100%;}
}

.tutors-publication-slider {
    @include lg {}
    @include md {display: none}
    @include sm {display: none}
    @include xs {display: none}
}

.publication-tag {
    font-weight: 500;
    font-size: 16px;
    margin: 0 0 0.5rem 0;
}

.publication-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;

}

.publication-div {
    display: flex;
    flex-direction: row;
    min-width: 300px;
    max-width: 300px;
}

.publication-img {
    min-width: 90px;
    height: 90px;
    overflow: hidden;

    object-fit: cover;
}

.publication-text-group {
    display: flex;
    flex-direction: column;

    margin-left: 1rem;
}

.publication-text-group > p {
    margin: 0;
}

.publication-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    padding-bottom: 0.2rem;
}

.publication-paragraph {
    font-size: 12px;
    line-height: 120%;
}
