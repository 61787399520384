@use '~/src/breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.navbar {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto 0 auto;

    font-weight: 600;
    text-transform: uppercase;
    padding: 0px 0 0px 0;
}

.navbar-full {
    margin: 0 auto !important;
    border-bottom: 0.5px solid rgb(200, 200, 200);
}

.navbar-logo {
    width: 225px;
}

.navbar-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    max-width: 80%;

    width: 60%;
    @include lg {width: 70%;}
}

.nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 100%;
    margin-right: 1rem;
}

.nav-items > p {
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: black;
}

.nav-items > p > a {
    color: black;
    font-weight: 500;
}

.nav-cta {
    background-color: var(--red);
    color: white;
    font-weight: 600;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-radius: 8px;
}


/* === Navbar Dropdown === */

.dropper {
    position: relative;
}

.dropdown {
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 100;

    border: 1px solid rgb(200, 200, 200);
}

.dropdown-container {
    display: flex;
    flex-direction: row;

    padding: 2rem;
    @include lg {padding: 1rem;}
}

.dropdown-left {
    margin-right: 2rem;

    width: 18%;
    @include lg {margin-right: 1rem;}
}
.dropdown-right {
    width: 80%;
    border-left: 0.5px solid rgb(200, 200, 200);
    background-color: white;

    padding-left: 2rem;
    @include lg {padding-left: 1rem;}
}

.dropdown-right-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropdown-right-top-left {
    width: 22%;
}

.dropdown-right-top-right {
    width: 74%;
}

.dropdown-right-top-right > img {
    width: 100%;
}

.dropdown-right-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    margin-top: 2rem;
    @include lg {margin-top: 1.5rem;}
}

.dropdown-right-bottom-col1 {
    width: 25%;
    border-right: 0.5px solid rgb(200, 200, 200);
}

.dropdown-right-bottom-col2 {
    margin-left: 2rem;
    @include lg {margin-left: 1rem;}

    width: 25%;
    border-right: 0.5px solid rgb(200, 200, 200);
}
.dropdown-right-bottom-col3 {
    margin-left: 2rem;
    @include lg {margin-left: 1rem;}

    width: 25%;
    border-right: 0.5px solid rgb(200, 200, 200);
}
.dropdown-right-bottom-col4 {
    margin-left: 2rem;
    @include lg {margin-left: 1rem;}

    width: 25%;
}

.dropdown-heading {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.dropdown-text {
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 300;
}

.dropdown-subjects {
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin-top: 12px;
}

.dropdown-subjects > li > a {
    color: black;
    font-size: 14px;
    line-height: 1.75rem;
    font-weight: 300;
}

.dropdown-nav-link {
    font-size: 14px;
    color: rgb(92, 122, 255);
}


/* ====== TABLET & MOBILE ====== */
/* ====== TABLET & MOBILE ====== */
@media only screen and (max-width: 991px) {
    .navbar {
        display: none;
    }


}