@use '~/src/breakpoints.scss' as *;

@media only screen and (min-width: 992px) {
    .mNav-group {
        display: none;
    }
}

.mNav-container {
    padding: 1rem 1.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;

    border-bottom: 0.5px solid rgb(200, 200, 200);
}

.mNav-hamburger > a > img {
    cursor:pointer;
    object-fit: contain;
    width: 30px;
    width: 30px;
    border-radius: 0;
}

.mDropdown-container {
    display: flex;
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 200;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;

    border-bottom: 0.5px solid rgb(200, 200, 200);
}

.mDropdown-subjects {
    width: 100%;
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

.mDropdown-subjects > li {
    border-bottom: 0.5px solid rgb(200, 200, 200);
}

.mDropdown-subjects > li > a {
    color: black;
    font-size: 14px;
    line-height: 1.75rem;
    font-weight: 300;
    min-width: 100%;
    padding: 1rem 1.5rem;
}

.mDropdown-subjects > li > a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}