@use '~/src/breakpoints.scss' as *;

.generalfold-group {
    margin-bottom: 10rem;
}

.generalfold-container {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;

    flex-direction: row;
    @include lg {flex-direction: row;}
    @include md {flex-direction: row;}
    @include sm {flex-direction: column;}
    @include xs {flex-direction: column;}
}

.general-full-group {
    display: grid;

    grid-template-columns: 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
}

.general-half-group {
    display: grid;

    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
}

.generalfold-video {
    border-radius: 8px;
    margin-bottom: 1.5rem;
    height: 500px;
    @include lg {height: 400px;}
    @include md {height: 300px;}
    @include sm {height: 300px;}
    @include xs {height: 200px;}
}

.general-half-info {
    width: 100%;
}

.generalfold-left {
    width: 25%;
    @include lg {width: 25%;}
    @include md {width: 25%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}

    top: 10px;

    height: 500px;
    @include lg {height: 500px;}
    @include md {height: 500px;}
    @include sm {height: auto;}
    @include xs {height: auto;}

    position: sticky;
    @include lg {position: static;}
    @include md {position: static;}
    @include sm {position: static;}
    @include xs {position: static;}
}

.generalfold-left > h2 {
    margin-bottom: 1rem;
    font-weight: 400;
}

.generalfold-left > p {
    line-height: 2rem;
    font-weight: 300;
    font-size: 18px;
    color: #333;
}

.generalfold-list {
    margin-bottom: 4rem;
    @include lg {margin-bottom: 4rem;}
    @include md {margin-bottom: 4rem;}
    @include sm {margin-bottom: 1rem;}
    @include xs {margin-bottom: 1rem;}
}

.generalfold-list-contents {
    font-weight: 600 !important;
}

.generalfold-list > p {
    margin: 0;
    line-height: 2.25rem;
    font-size: 18px;
    font-weight: 300;
}

.generalfold-right {
    width: 70%;
    overflow: hidden;
    border-radius: 8px;

    @include lg {width: 70%;}
    @include md {width: 70%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}
}

.generalfold-right > img {
    padding-top: 40px;
    width: 100%;
}

.generalfold-grid {
    display: flex;
    flex-direction: column;
}

.generalfold-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.generalfold-unit {
    margin-right: 0.5rem;
}

.generalfold-unit > span {
    font-size: 48px;
    font-weight: 700;
    color:#6211E2;
    display: block;
}

.generalfold-unit > p {
    margin: 0;
    display: inline;
}

.info-right-paragraph-full {
    width: 100%;
}

.info-group-text-full > p {
    font-weight: 300;
    line-height: 2rem;
}

/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
@media only screen and (max-width: 478px) {
    .generalfold-group {
        margin-bottom: 3rem;
    }
    
    .generalfold-container {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }
    
    .general-half-group {
        display: grid;
    
        grid-template-columns: 1fr;
        column-gap: 0rem;
        row-gap: 1rem;
    }

    .generalfold-right {
        width: 100%;
        overflow: hidden;
        border-radius: 8px;
    }

    .generalfold-list {
        margin-bottom: 1rem;
    }
    
    .generalfold-list-contents {
        font-weight: 600 !important;
    }
    
    .generalfold-list > p {
        margin: 0;
        line-height: 2.25rem;
        font-size: 18px;
        font-weight: 300;
    }
}