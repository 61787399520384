@use '~/src/breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.blog-article-wide-group {
    display: flex;
    flex-direction: column;
    border: solid 1px #E2E2E2;
    border-radius: 8px;

    max-width: 285px;
    min-width: 200px;
    align-self: baseline;

    overflow: hidden;

    @include lg {}
    @include md {}
    @include sm {}
    @include xs {max-width: 100%; margin-bottom: 1rem;}
}

.blog-article-wide-top {
    background-color: grey;
    overflow: none;
}

.blog-article-wide-top > img {
    width: 100%;
    border-radius: 0px;
}

.blog-article-wide-bottom {
    padding: 0.5rem 1.25rem 0rem 1rem;

    @include lg {}
    @include md {}
    @include sm {}
    @include xs {padding: 0.5rem 1rem 0rem 1rem;}
}

.blog-article-wide-subject {
    margin: 0 !important;
    font-weight: 300;
    font-size: 14px;
    color: #005CE7;
}

.blog-article-wide-topic {
    margin: 0 !important;
    font-size: 22px;
    font-weight: 500;

    @include lg {font-size: 18px;}
    @include md {font-size: 18px;}
    @include sm {font-size: 18px;}
    @include xs {font-size: 18px;}
}

.blog-article-wide-paragraph {
    margin-top: 0.5rem !important;
    font-size: 16px;
    line-height: 130%;

    @include lg {font-size: 14px;}
    @include md {font-size: 14px;}
    @include sm {font-size: 14px;}
    @include xs {font-size: 14px;}
}