@use '~/src/breakpoints.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Archivo:300,400,500,600,700,800');
@import url('https://css.gg/css');

.tablist-selected {
    color: #6211E2;
    font-weight: 600;
}

.tablist-item:focus {
    outline: none;
}

/* === Info Section === */
.info-container {
    margin: 4rem 0 4rem 0;
    display: flex;

    justify-content: space-between;
    @include sm {flex-direction: column;}
    @include xs {flex-direction: column;}
}

/* === Info Section - Left === */
.info-left {
    width: 26%;
    @include md {width: 45%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}

    display: flex;
    flex-direction: column;
}

.info-left-bottom {
    margin-top: 3rem;
    @include lg {margin-top: 1rem;}
    @include md {margin-top: 1rem; width: 80%;}
    @include sm {display: none;}
    @include xs {display: none;}

}

.info-header > h2 {
    margin-bottom: 1rem;
    margin-top: 0;
    
    width: auto;
    @include md {width: 80%;}
}

.tablist {
    list-style: none;
    line-height: 2.25rem;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;

    padding-left: 0px;

    min-width: auto;
    @include lg {min-width: 400px;}
}

.info-testimonial {
    width: 100%;
}

.info-testimonial-text {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.5rem;

    margin-bottom: 1rem;
}

.info-testimonial-name {
    font-weight: 600;
    color: red;
}

.info-testimonial-school {
    font-size: 12px;
    font-weight: 600;
}

.info-testimonial-marks {
    font-size: 12px;
    font-weight: 600;
    color:#6211E2;
}

.info-testimonial-image {
    width: 50px;
    border-radius: 30px;
    overflow: hidden;
}

.info-testimonial-image > img {
    width: 100%;
}

.info-testimonial-person {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-testimonial-details {
    margin-left: 0.5rem;
    font-size: 14px;
}

.info-testimonial-details > p {
    margin: 0;
    line-height: 1rem;
}

/* === Info Section - Right === */
.info-right {
    width: 70%;
    @include sm {width: 100%;}
    @include xs {width: 100%;}
}

.info-right-image {
    width: 100%;
}

.info-video {
    height: 500px;
    @include lg {height: 400px;}
    @include md {height: 280px;}
    @include sm {height: 370px;}
    @include xs {height: 190px;}
}

.info-doc {
    height: 480px;
    @include lg {height: 400px;}
    @include md {height: 280px;}
    @include sm {height: 370px;}
    @include xs {height: 190px;}
}

.info-right-paragraph {
    display: flex; 
    align-items: baseline;
    justify-content: space-between;
    @include md {flex-direction: column; justify-content: space-between; gap: 1rem;}
    @include sm {flex-direction: column; justify-content: space-between; gap: 1rem;}

    margin-top: 2rem;
}

.info-paragraph-heading {
    font-weight: 600;

    font-size: 22px;
    @include lg {font-size: 20px;}
    @include md {font-size: 20px;}
    @include sm {font-size: 18px;}
}

.info-group-text {
    width: 50%;
    @include md {width: 90%;}
    @include sm {width: 100%;}
    @include xs {margin-bottom: 1rem;}
}

.info-group-text > p {
    margin-bottom: 0;

    @include lg {margin-top: 0.75rem;}
    @include md {margin-top: 0.75rem;}
}

.info-group-text-full {
    width: 100%;
}

.info-group-text > p {
    font-size: 18px;
    font-weight: 300;
    padding-right: 1rem;
    line-height: 2rem;
}

/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */

@media only screen and (max-width: 478px) {
    /* === Info Section === */
    .info-container {
        margin: 3rem 0 4rem 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column; 
    }

    .info-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .info-testimonial {
        display: none;
        width: 100%;
        margin-bottom: 2rem;
    }

    .info-right {
        width: 100%;
    }

    .info-right-paragraph {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .info-group-text {
        width: 100%;
    }

    .info-group-text > p {
        font-size: 18px;
        font-weight: 300;
        padding-right: 1rem;
        line-height: 2rem;
    }

    .tablist {
        line-height: 2rem;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
    
        padding-left: 0px;
    }

    .info-paragraph-heading {
        font-size: 20px;
        font-weight: 600;
    }

    .info-group-text > p {
        font-size: 16px;
        font-weight: 300;
        padding-right: 1rem;
        line-height: 2rem;
    }

}