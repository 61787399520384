// $sm: 640px;
// $md: 768px;
// $lg: 1024px;
// $xl: 1280px;
$sm: 478px;
$md: 767px;
$lg: 991px;
$xl: 1280px;

$xxl: 1536px;

@mixin lg {
    //1280 - 991
    @media screen and (min-width: #{$lg+1}) and (max-width: #{$xl}) {
        @content;
    }
}

@mixin md {
    //991 - 767
    @media screen and (min-width: #{$md+1}) and (max-width: #{$lg}) {
        @content;
    }
}

@mixin sm {
    //767 - 478
    @media screen and (min-width: #{$sm+1}) and (max-width: #{$md}) {
        @content;
    }
}

@mixin xs {
    //478 - 0
    @media screen and (max-width: #{$sm}) {
        @content;
    }
}

