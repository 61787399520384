@use '~/src/breakpoints.scss' as *;
/* === Results === */
.results-container {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.results-left {
    width: 45%;
    @include lg {width: 60%;}
    @include md {width: 100%;}
    @include sm {width: 100%;}
    @include xs {width: 100%;}

    margin-right: auto;
    @include lg {padding-right: 3rem;}
}

.results-left > h2 {
    margin-bottom: 1rem;
}

.results-left > p {
    font-weight: 400;
    font-size: 18px;
    color: #333;

    width: auto;
    @include md {width: 80%;}
}

.results-right {
    overflow: hidden;

    width: 50%;
    @include lg {width: 40%; height: auto;}
    @include md {width: 0;}
    @include sm {width: 0;}
    @include xs {width: 0;}

}

.results-right > img {
    margin-top: 40px;

    object-fit: fill;
    width: 100%;
    @include lg {object-fit: cover; width: 100%; height: 100%;}
    @include md {display: none;}
    @include sm {display: none;}
    @include xs {display: none;}
}

.results-grid {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    margin-top: auto;
    @include md {margin-top: 2rem;}

    row-gap: 0rem;
    @include md {row-gap: 1rem;}

    column-gap: 1rem;
    @include md {column-gap: 5rem;}
}

.results-grid > div {
    display: flex;
    flex-direction: column;
}

.results-grid > div > span {
    
    font-weight: 700;
    color:#6211E2;
    display: block;

    font-weight: 700;
    @include lg {font-weight: 600;}
    @include md {font-weight: 600;}
    @include sm {font-weight: 600;}
    @include xs {font-weight: 600;}

    font-size: 42px;
    @include lg {font-size: 36px;}
    @include md {font-size: 32px;}
    @include sm {font-size: 32px;}
    @include xs {font-size: 24px;}
}

.results-grid > div > p {
    margin: 0;
    display: inline;
}

/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
/* ====== MOBILE SIZE ====== */
@media only screen and (max-width: 478px) {
    .results-container {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .results-left {
        width: 100%;
    }
    
    .results-left > h2 {
        margin-bottom: 1rem;
    }
    
    .results-left > p {
        font-weight: 400;
        font-size: 18px;
        color: #333;
    }
    
    .results-right {
        width: 50%;
        display: none;
    }
    
    .results-right > img {
        margin-top: 40px;
        width: 100%;
    }

    .results-grid {
        display: grid;
        flex-direction: column;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        row-gap: 0rem;
        column-gap: 1rem;
    }
    
    .results-grid > div {
        display: flex;
        flex-direction: column;
    }
    
    .results-grid > div > span {
        font-size: 36px;
        font-weight: 700;
        color:#6211E2;
        display: block;
    }
    
    .results-grid > div > p {
        margin: 0;
        display: inline;
    }

}